import { BrowserRouter, Route, Switch } from "react-router-dom";
import './App.scss';

import { Navbar } from './components/shared/Navbar/Navbar';
import { SurveyBuilder } from './components/Survey/SurveyBuilder';
import { ProtectedRoute } from "./components/shared/ProtectedRoute/ProtectedRoute";
import { Import } from './components/Analysis/Import/components/dashboard/main/import/Import';
import { Analyze } from './components/Analysis/Analyze/components/dashboard/main/analysis/Analysis';
import { Pinboards } from './components/Pinboards/Pinboards'
import { Login } from './components/Login/Login';
import { Admin } from './components/Admin/Admin';
import { Panel } from './components/Panel/Panel';
import { Files } from './components/Files/Files';
import { SignUp } from './components/SignUp/SignUp';
import { Workflow } from './components/Workflow/Workflow';
import { Dashboard } from './components/Dashboard/Dashboard';
import { useSignalR } from './components/shared/customHooks/useSignalR';
import { SubAccountLogin } from './components/SubAccountLogin/SubAccountLogin';
import { SelectCustomer } from './components/shared/SelectCustomer/SelectCustomer';
import { Projects } from './components/Project/Projects/Projects';
import { ProjectDetails } from './components/Project/ProjectDetails/ProjectDetails';
import { Gatekeeper } from './components/Gatekeeper/Gatekeeper';
import { Backoffice } from './components/Backoffice/Backoffice';
import { Tasks } from './components/Tasks/Tasks';
import { TrialExpired } from './components/TrialExpired/TrialExpired';
import { AudienceOverview } from './components/Audience/AudienceOverview';
import { Audiences } from './components/AudienceOverview/Audiences';
import { SurveyNewOverviewTabContent } from './components/Survey/SurveyTabContent/SurveyNewOverviewTabContent/SurveyNewOverviewTabContent';
import { initAnalytics } from "./services/appInsights/appInsights";
import { useAuth0 } from "@auth0/auth0-react";

declare global {
  type TODO = any;
  interface Window {
    FreshworksWidget: any;
  }
}

export const App = () => {
  useSignalR();

  const FreshworksWidget = window.FreshworksWidget
  if (FreshworksWidget) {
    FreshworksWidget('hide', 'launcher');
  }

  // Set user information in AppInsights
  const users = useAuth0();
  initAnalytics(users?.user || {});

  return (
    <div className="d-flex">
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/welcome" component={SubAccountLogin} />
          <Route path="/customers" component={SelectCustomer} />
          <Route path="/register/:registerType/:key" component={SignUp} />
          <Route path="/public/pinboards/:shareKey" component={Pinboards} />
          <Route path="/public/dashboards/:shareKey" component={SurveyNewOverviewTabContent} />
          <Route path="/trial-expired" component={TrialExpired} />
          <Route path="*" component={Navbar} />
        </Switch>
        <Switch>
          <ProtectedRoute exact path="/" component={Dashboard} />
          <ProtectedRoute path="/projects/:name/:sample/panel" component={Panel} />
          <ProtectedRoute path="/admin" component={Admin} />
          <ProtectedRoute path="/projects/:name/import" component={Import} />
          <ProtectedRoute path="/projects/:name/:dataset/analyze/:analysisType" component={Analyze} />
          <ProtectedRoute path="/projects/:name/:survey/survey-builder" component={SurveyBuilder} />
          <ProtectedRoute path="/projects/:name/:pinboard/pinboards" component={Pinboards} />
          <ProtectedRoute exact path="/audiences/create" component={AudienceOverview} />
          <ProtectedRoute exact path="/projects/:name/audiences/:id?" component={AudienceOverview} />
          <ProtectedRoute path="/projects/:name/:workflow/workflow" component={Workflow} />
          <ProtectedRoute exact path="/projects/:name" component={ProjectDetails} />
          <ProtectedRoute exact path="/projects" component={Projects} />
          <ProtectedRoute path="/files" component={Files} />
          <ProtectedRoute path="/gatekeeper" component={Gatekeeper} />
          <ProtectedRoute path="/backoffice" component={Backoffice} />
          <ProtectedRoute path="/tasks" component={Tasks} />
          <ProtectedRoute exact path="/audiences" component={Audiences} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
