import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import type { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { createBrowserHistory } from "history";
import { CONFIG } from '../../appConfig';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  autoCapture: true,
};
const appInsights = new ApplicationInsights({
  config: {
    connectionString: CONFIG.appInsightsConnectionString,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: clickPluginConfig
    }
  }
});
appInsights.loadAppInsights();

const initAnalytics = (user: { name?: string | undefined}) => {

  appInsights.setAuthenticatedUserContext(
    user.name || 'anonymous',
    '',
    true
  );

  appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
    env.tags = env.tags || {};
    env.tags["ai.cloud.role"] = "app-role-platform-web";
    env.tags['ai.app.id'] = __APP_VERSION__;
    // Add custom user properties
    env.data = env.data || {};
    env.data["ms-appName"] = "platform-web";
    env.data["ms-appId"] = __APP_VERSION__;

  });
};

export { reactPlugin, appInsights, initAnalytics };
